import React from 'react'
import SEO from '../components/seo'
import Header from '../components/Header/Header'
import '../styles/index.scss'
import PageWrapper from '../shared/PageWrapper/PageWrapper'
import Layout from '../shared/Layout/layout'
import BottomNav from '../components/BottomNav/BottomNav'
import Offer from '../components/Offer/Offer'

const TilbodPage = props => {
  const seo = {
    title: `101 Tilboð | ${props.pageContext.tilbod.title}`,
    description: `${props.pageContext.tilbod.text_larger}`,
    image: `${props.pageContext.tilbod.image}`,
  }
  return (
    <>
      <SEO {...seo} />
      <PageWrapper>
        <Layout>
          <Offer offer={props.pageContext.tilbod} daysActive={props.pageContext.daysActive} />
        </Layout>
      </PageWrapper>
    </>
  )
}

export default TilbodPage
