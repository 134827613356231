import React from 'react'
import SemiText from '../../shared/SemiText/SemiText'
import BigText from '../../shared/BigText/BigText'
import Text from '../../shared/Text/Text'
import MonospacedText from '../../shared/MonospacedText/MonospacedText'
import './Offer.scss'

export default ({ offer, daysActive }) => {
  return (
    <div style={{ marginTop: '100px' }}>
      <img src={offer.image} alt="" className="img-fluid w-100" />
      <div className="offer_text_section">
        <div style={{margin: '35px 0px 10px'}}>
          <BigText text={offer.title} />
        </div>
        <div className="rich_text_container">
          <p>{offer.text_larger}</p>
          <p>{offer.text}</p>
        </div>
      </div>
      <div className="offer_text_section">
        <MonospacedText text={offer.time} />
        <div className="flex" style={{ display: 'flex' }}>
          <div className="offer_day">
            <span className={`offer_day_circle ${daysActive[0].active ? 'active' : ''}`} />
            <MonospacedText eStyles={{ fontSize: '.6em' }} white capitalize text="Mán" />
          </div>
          <div className="offer_day">
            <span className={`offer_day_circle ${daysActive[1].active ? 'active' : ''}`} />
            <MonospacedText eStyles={{ fontSize: '.6em' }} white capitalize text="Þri" />
          </div>
          <div className="offer_day">
            <span className={`offer_day_circle ${daysActive[2].active ? 'active' : ''}`} />
            <MonospacedText eStyles={{ fontSize: '.6em' }} white capitalize text="Mið" />
          </div>
          <div className="offer_day">
            <span className={`offer_day_circle ${daysActive[3].active ? 'active' : ''}`} />
            <MonospacedText eStyles={{ fontSize: '.6em' }} white capitalize text="Fim" />
          </div>
          <div className="offer_day">
            <span className={`offer_day_circle ${daysActive[4].active ? 'active' : ''}`} />
            <MonospacedText eStyles={{ fontSize: '.6em' }} white capitalize text="Fös" />
          </div>
          <div className="offer_day">
            <span className={`offer_day_circle ${daysActive[5].active ? 'active' : ''}`} />
            <MonospacedText eStyles={{ fontSize: '.6em' }} white capitalize text="Lau" />
          </div>
          <div className="offer_day">
            <span className={`offer_day_circle ${daysActive[6].active ? 'active' : ''}`} />
            <MonospacedText eStyles={{ fontSize: '.6em' }} white capitalize text="Sun" />
          </div>
        </div>
      </div>
      <div className="offer_text_section more_info_container">
        <span className="subheading">STAÐSETNING</span>
        <a href={`https://www.google.com/maps/place?q=${encodeURIComponent(offer.area)}`}><SemiText style={{ marginTop: 0 }} text={offer.area} /></a>
      </div>
      <div className="offer_text_section more_info_container">
        <span className="subheading">VEFSÍÐA</span>
        <a href={offer.link} target="_blank">
          <SemiText text={offer.link_text} />
        </a>
      </div>
    </div>
  )
}
